.home{
    background: #fff;
    color: rgb(44, 44, 44);
}

.title-group h3{
    font-size: 42px;
    font-weight: bold;
}

.title-group p{
    color: var(--color-grey-dark);
    font-size: 18px;
}

.divisor{
    height: 3px;
    width: 10%;
    background: var(--color-yellow);
    border: none;
}

.divisor-white{
    height: 3px;
    width: 10%;
    background:#fff;
    border: none;
}

.padding-section{
    padding-top: 80px;
    padding-bottom: 80px;
}

.color-yellow{
    color: var(--color-yellow)
}