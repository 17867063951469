section.login{
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle,#2b2f38,#15171b);
    display: flex;
    align-items: center;
}

section.login .perfil-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}


section.login .perfil-card .icon{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 30px;
    color: #000;
    background: var(--background-yellow);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid #fff;
    z-index: 2;
    
}

section.login .perfil-card .card{
    text-align: center;
    margin-top: -40px;
    padding: 50px 30px 20px;
    background: #fff;
    box-shadow: 0 10px 30px #000;
    border-radius: 3px;
    width: 80%;
}
section.login .perfil-card .card h4{
    font-weight: bold;
    font-size: 28px;
}

section.login .perfil-card .text-yellow{
    color: #616161;
    font-style: italic;
}

section.login .perfil-card .text-nobold{
    font-weight: 100;
}

section.login .perfil-card button{
    color: #000;
    width: 100%;
}

section.login  .button-not-client {
    margin-top: 70px;
}


section.login  .button-not-client .notclient-link{
    color: #fff;
    opacity: .3;
    border-radius: 50px;
    border: 2px solid #fff;
    padding: 10px 20px;
}

section.login .button-not-client .notclient-link:hover{
    background: #fff;
    color: #000;
    text-decoration: none;
}