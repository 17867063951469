
.player-video{
    position: absolute;
    color: #fff;
    font-size: 80px;
    top: 30%;
    left: 44.5%;
}

.player-video:hover{
    cursor: pointer;
    color: #dfdfdf;
}

@media(min-width: 992px) and (max-width: 1199px){
    .player-video{
        top: 28%;
        left: 43%;
    }
}

@media(min-width: 768px) and (max-width: 991px){
    .player-video{
        top: 15%;
        left: 40%;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .player-video{
        top: 22%;
        left: 42%;
    }
}

@media(min-width: 350px) and (max-width: 424px){
     .player-video{
        top: 20%;
        left: 40%;
    }
}

@media(max-width: 349px){
    .player-video{
        font-size: 60px;
        top: 22%;
        left: 42%;
    }
}