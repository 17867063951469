.vertical-timeline-element-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-timeline-element-icon i{
    font-size: 28px
}


@media(max-width: 1169px){
    .vertical-timeline-element-icon i{
        font-size: 20px
    }
}