.totalizador{
    background: var(--background-yellow)
}

.totalizador img{
    width: 100%;
}

.totalizador hr{
   background:  rgb(233, 195, 43);
   margin-bottom: 40px;
   margin-top: 40px;
}

.totalizador-group{
    display: flex;
    align-items: center;
    color: rgb(44, 44, 44);
}

.totalizador-group i{
    font-size: 40px;
}

.text-totalizador{
    font-weight: 500;
    font-size: 30px;
    white-space: nowrap;
}

.text-totalizador .title{
    font-size: 20px;
}

.desc-totalizador{
    font-size: 26px;
    line-height: 36px;
    color: rgb(44, 44, 44);
}

.icon-total{
    width: 80px;
    height: 80px;
    background: #fcfcfc;
    padding: 5px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}   


@media(max-width: 320px){
    .desc-totalizador{
        font-size: 20px;
    }

    .text-totalizador{
        font-size: 25px;
    }
    .text-totalizador .title{
        font-size: 16px;
    }

    .icon-total{
        width: 70px;
        height: 70px;
    }

    .icon-total i{
        font-size: 30px;
    }

}