.navbar{
    background: rgba(0, 0, 0, 0.4) !important;
}

.navbar-brand{
    margin-left: 50px;
}

.navbar-brand img{
    width: 75%;
}

.navbar .nav-item{
    text-transform: uppercase;
    font-size: 16px;
    color: #fff !important;
    margin-left: 10px;
    margin-right: 10px;
}

.navbar .active{
    border-bottom: 1px solid #fff;
}


.header .btn-primary{
    color: #61500c;
    margin-left: 50px;
    margin-right: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.painel-button i {
    font-size: 14px;
    margin-right: 5px;
}

@media(max-width: 991px){
    .header .btn-primary{
        margin-right: 0px;
    }
}

@media(max-width: 475px){
    .navbar-brand{
        margin-left: 0px;
    }

    .navbar-brand{
        width: 75%;
    }
}