footer{
    background-color: #2B2F38;
    color: var(--color-yellow);
}

footer h4{
    font-weight: bold;
    font-size: 28px;
}

footer hr{
    background: #fff;
}

footer .info-text{
    color: #CCCC;
}

footer a{
    color: var(--color-yellow);
}

footer a:hover{
    color: var(--color-yellow);
}

footer .footer-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 1px solid var(--color-yellow);
    border-radius: 100%;
}

footer .midias-sociais i{
    font-size: 30px;
}

footer .midias-sociais i:hover{
    filter: brightness(0.8);
    font-size: 30px;
}

footer .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}