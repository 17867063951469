.produto-selecionado{
    /* box-shadow: 0 8px 40px 0 rgba(103,151,255,.11), 0 12px 70px 0 rgba(103,151,255,.11); */
    padding: 20px;
}

.divisor-yellow-right{
    height: 3px;
    width: 20%;
    background: var(--color-yellow);
    border: none;
}   

.produtos img{
    width: 70%;
}

.produtos ul{
     list-style-type: none;
}


.produtos .btn-produto{
    border-top: 3px solid var(--color-yellow);
    width: 100%;
    text-align: center;
    padding: 16px;
    background-color: #fff;
    color: #20292f!important;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 900;
    display: flex;
    /* box-shadow: 0 8px 40px 0 rgba(103,151,255,.11), 0 12px 70px 0 rgba(103,151,255,.11); */
}

.btn-produto:focus, .btn-produto:active{

   outline: none !important;
   box-shadow: none;
}

.produtos .btn-produto:hover{
    filter: brightness(0.98);
}

.button-display-sm{
    display: none !important;
}

.button-display-lg{
    display: flex;
}


@media(max-width: 767px){
    .button-display-sm{
        display: flex !important;
        justify-content: center;
    }

    .button-display-lg{
        display: none !important;
    }
}

@media(max-width: 425px){
    .title-group h1{
        font-size: 30px;
    }

    .title-group p{
        font-size: 15px;
    }

    .lista-li{
        font-size: 14px;
    }

}

@media(max-width: 332px){
    .produtos .btn-produto{
        font-size: 10px;
    }
}