.desc-app{
    font-size: 25px;
}

.icone {
    width: 170px;
    height: 170px;
    padding: 5px;
    border-radius: 10px;
    
}

.icone img{
    width: 100%;
}

.icone:hover{
    filter: brightness(0.9);
}

.android{
    background: #81BB00;
}

.ios{
    background: #2A2A2A;
}

@media(min-width: 768px) and (max-width: 991px){
    .icone{
        width: 150px;
        height: 150px;
    }
}