/* DIRTY Responsive pricing table CSS */

/* 
- make mobile switch sticky
*/
article {
  width:100%;
  max-width:1000px;
  margin:0 auto;
  min-height:1120px;
  position:relative;
}
.plano-table ul {
  display:flex;
  top:0px;
  z-index:10;
  padding-bottom:14px;
}
.plano-table li {
  list-style:none;
  flex:1;
}
.plano-table li:last-child {
  border-right:1px solid #DDD;
}
.plano-table button {
  width:100%;
  border: 1px solid #DDD;
  border-right:0;
  border-top:0;
  padding: 10px;
  background:#FFF;
  font-size:14px;
  font-weight:bold;
  height:60px;
  color:#999
}
.plano-table li.active button {
  background:#F5F5F5;
  color:#000;
}
.plano-table table { border-collapse:collapse; table-layout:fixed; width:100%; }
.plano-table th { background:#F5F5F5; display:none; }
.plano-table td, th {
  height:53px
}
.plano-table td,th { border:1px solid #DDD; padding:10px; empty-cells:show; }
.plano-table td,th {
  text-align:left;
}
.plano-table td+td, th+th {
  text-align:center;
  display:none;
}
.plano-table td.default {
  display:table-cell;
}
.plano-table .bg-grey {
    font-size: 20px;
  	border-top:3px solid rgb(192, 192, 192);
}
.plano-table .bg-black {
    font-size: 20px;
  	border-top:3px solid var(--color-grey-dark);
}
.plano-table .sep {
  background:#F5F5F5;
  font-weight:bold;
}
.plano-table .txt-l { font-size:28px; font-weight:bold; }
.plano-table .txt-top { position:relative; top:-9px; left:-2px; }
.plano-table .txt-s { font-size:14px }
.plano-table .tick { font-size:18px; color:#2CA01C; }
.plano-table .no-tick {color: #d40c0c}
.plano-table .hide {
  border:0;
  background:none;
}

@media (min-width: 768px) {
  .plano-table ul {
    display:none;
  }
  .plano-table td,th {
    display:table-cell !important;
  }
  .plano-table td,th {
    width: 330px;
  
  }
  .plano-table td+td, th+th {
    width: auto;
  }
}