.recursos{
    background: var(--color-grey);
}

.recursos-icon{
    font-size: 40px;
    border-radius: 100%;
    background-color: #f4f4f4;
    width: 86px;
	height: 86px;
	display: inline-block;
	line-height: 86px;
	color: var(--color-yellow)
}

.recursos-icon:hover{
	background-color: var(--background-yellow);
	color: #fff;
}

.recursos .card{
	min-height: 330px;
}

.em-desenvolvimento .card::after{
	content: "MÓDULO EM DESENVOLVIMENTO";
    position: absolute;
    top: 20px;
    transform: rotate(-36deg);
    width: 240px;
    text-align: center;
    color: rgb(255, 255, 255);
    line-height: 1.4rem;
    left: -50px;
    font-weight: 600;
    background: rgb(247, 205, 36);
    padding: 5px;
}

.em-desenvolvimento .card{
	display: block;
	position: relative;
	overflow: hidden;
}


@media(min-width: 768px) and (max-width: 1199px){
    .recursos .card{
        min-height: 370px;
    }
}