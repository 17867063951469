.carrorama-banner{
    width: 100%;
    height: auto;
}


.banner{
    background: url('../../assets/images/carrorama-banner.png');
    background-attachment: fixed;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    position: relative;
    overflow: hidden;
    padding-top: 100px;

}


.banner::after{
    content: "";
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 78px;
    background: url('../../assets/images/bottom-banner.png') no-repeat 0 0;
    background-size: cover;
    display: inline-block;
    position: absolute;
    z-index: 9;
    box-sizing: border-box;
}

.banner-text{
    color: #fff;
    z-index: 99;
    position: relative;
    margin-top: 13%;
}


.banner-text .text{
    padding-left: 80px;
}

.banner-text h1{
    font-size: 64px;
    font-weight: bold;
}

.bottom-banner img{
    width: 100%;
}


.banner-text img{
    width: 100%;
}


@media(min-width: 992px) and (max-width: 1199px){
    .banner-text .text{
        padding: 30px;
    }

    .banner-text h1{
        font-size: 50px;
    }
    
    .banner-text p{
        font-size: 13px;
    }

}

@media (min-width: 425px) and (max-width: 500px){
    .banner-text .text{
        padding-left: 20px;
    }
    .banner-text h1{
        font-size: 40px;
    }
}

@media(min-width: 501px) and (max-width: 600px){
    .banner-text .text{
        padding-left: 20px;
    }

    .banner-text h1{
        font-size: 45px;
    }
}

@media(min-width: 601px) and (max-width: 768px){
    .banner-text .text{
        padding-left: 40px;
    }

    .banner-text h1{
        font-size: 55px;
    }
}

@media (max-width: 630px){
    .text p {
        white-space: normal !important;
    }
    .text p br{
        display: none;
    }
}


@media(min-width: 376px) and (max-width: 424px){
    .banner-text h1{
        font-size: 35px;
    }
    .banner-text .text{
        padding-left: 20px;
    }

}

@media(min-width: 330px) and (max-width: 375px){
     .banner-text h1{
        font-size: 30px;
    }
    .banner-text .text{
        padding-left: 20px;
    }
    
    .banner-text p{
        font-size: 14px;
    }
}

@media(max-width: 329px){
    .banner-text h1{
        font-size: 28px;
    }
    .banner-text .text{
        padding-left: 20px;
    }
    .banner-text p{
        font-size: 14px;
    }
}

@media(min-width: 541px) and (max-width: 720px){
    .banner{
        background: url('../../assets/images/carrorama-banner@720px.png');
        background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        position: relative;
        overflow: hidden;
        padding-top: 100px;

    }


    .banner::after{
        content: "";
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 78px;
        background: url('../../assets/images/bottom-banner@720px.png') no-repeat 0 0;
        background-size: cover;
        display: inline-block;
        position: absolute;
        z-index: 9;
        box-sizing: border-box;
    }
}

@media(max-width: 540px){
     .banner{
        background: url('../../assets/images/carrorama-banner@540px.png');
        background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        position: relative;
        overflow: hidden;
        padding-top: 100px;

    }

    .banner::after{
        content: "";
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 78px;
        background: url('../../assets/images/bottom-banner@540px.png') no-repeat 0 0;
        background-size: cover;
        display: inline-block;
        position: absolute;
        z-index: 9;
        box-sizing: border-box;
    }
}