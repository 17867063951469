.card-depoimento{
    background: #fff;
    padding: 30px;
    border: 1px solid #f2f2f2;
    border-bottom: 3px solid var(--color-yellow);
    margin-top: 80px;
}

.card-depoimento .nome-cliente{
    font-weight: 800;
    font-size: 20px;
}

.card-depoimento .cargo{
    color: #666666;
    font-weight: 600;
}
.card-depoimento .texto-depoimento{
    color: #666666;
}

.card-depoimento .cliente-imagem{
    width: 90px;
    height: 90px;
    display: inline-block;
    border-radius: 100%;
    border: 5px solid #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 40%);
}