body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
    --color-yellow: #f7cd24;
    --color-grey: #f5f5f5;
    --color-grey-dark: #666666;

    --background-yellow: #f7cd24;
    --background-grey-medium: #f5f7fb;
}

body{
    font-family: 'Ubuntu', sans-serif !important;
}

.btn-primary{
    background: var(--background-yellow) !important;
    border-color: var(--background-yellow) !important;
}

.btn-primary:hover{
    background: #d8b320 !important;
    border-color:#d8b320 !important;
}