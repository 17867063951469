.sobre-nos .text-sobre-nos{
    color: #666666;
}

.sobre-nos .img-sobre{
    width: 100%;
}

.sobre-nos .banner::after{
    display: none !important;
}

.sobre-nos .banner{
    padding-top: 200px !important;
}

.sobre-nos .timeline-carrorama{
    background: var(--color-grey);
}
