.contato{
     background: var(--background-grey-medium);
}

::-webkit-input-placeholder {
  font-size: 16px;
}
::-moz-placeholder {
  font-size: 16px;
}
:-ms-input-placeholder {
  font-size: 16px;
}
::placeholder {
  font-size: 16px;
}

input:focus, textarea:focus {
    outline: none !important;
    border:1px solid var(--color-yellow)  !important;
    box-shadow: 0 0 5px var(--color-yellow) !important;
}